export enum CustomerType {
  INSTRUCTING_INSTITUTION = 'INSTRUCTING_INSTITUTION',
  ORDERING_INSTITUTION = 'ORDERING_INSTITUTION',
  ORDERING_CUSTOMER = 'ORDERING_CUSTOMER',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
}

export enum KycStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum Status {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  PENDING_CREATION = 'PENDING_CREATION',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED_CREATION = 'REJECTED_CREATION',
  CREATED = 'CREATED',
  REJECTED = 'REJECTED',
  DEFAULT = '',
}

export enum SettlementMethod {
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  SWEEP = 'SWEEP',
  WIRE = 'WIRE',
  AUTO_DEBIT = 'AUTO_DEBIT',
}

export enum DisclosureType {
  PREPAYMENT_DISCLOSURE = 'DODD_FRANK_PRE_PAYMENT_DISCLOSURE',
  RECEIPT_DISCLOSURE = 'DODD_FRANK_RECEIPT_DISCLOSURE',
}

export enum AddOrEditOrView {
  Edit,
  Add,
  View,
  Approve,
}

export enum ClientForms {
  CustomerOverview = 'CustomerOverview',
  CustomerDetails = 'CustomerDetails',
  UserDetails = 'UserDetails',
  SiteDetails = 'SiteDetails',
  Settings = 'Settings',
}

export enum Disclaimers {
  LICENSING = 'LICENSING',
  DISCLAIMER = 'DISCLAIMER',
  DODD_FRANK_RECEIPT_DISCLOSURE = 'DODD_FRANK_RECEIPT_DISCLOSURE',
  DODD_FRANK_PRE_PAYMENT_DISCLOSURE = 'DODD_FRANK_PRE_PAYMENT_DISCLOSURE',
}

export enum MarginType {
  BASE = 'BASE',
  TIERED = 'TIERED',
  GROUP = 'GROUP',
  CURRENCY = 'CURRENCY',
}

export enum FeesType {
  FX_BASED = 'FX_BASED',
  SAME_TO_SAME = 'SAME_TO_SAME',
  CURRENCY_SPECIFIC = 'CURRENCY_SPECIFIC',
}

export enum SsoProvider {
  ADMIN = 'ADMIN',
}

export enum RequestQuickQuoteType {
  QuoteAmounCurrency = 'QuoteAmounCurrency',
  CustomerDetails = 'CustomerDetails',
  BeneficiaryDetails = 'BeneficiaryDetails',
}

export enum QuoteStatus {
  AddQuote = 'AddQuote',
  SubmittingQuote = 'SubmittingQuote',
  RecievingData = 'RecievingData',
  DataRecieved = 'DataRecieved',
  PaymentStarted = 'PaymentStarted',
  QuoteExpired = 'QuoteExpired',
}
export enum CustomerSubType {
  CONSUMER = 'CONSUMER',
  CORPORATE = 'CORPORATE',
}

export enum NOTIFICATION_STATUS {
  UNREAD = 'UNREAD',
  DELETED = 'DELETED',
}

export enum PaymentStatus {
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  APPROVAL = 'APPROVAL',
  COMMITTED = 'COMMITTED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
  COMPLIANCE_FAILED = 'COMPLIANCE_FAILED',
  PENDING_COMPLIANCE = 'PENDING_COMPLIANCE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ABANDONED = 'ABANDONED',
  PENDING_PARENT_APPROVAL = 'PENDING_PARENT_APPROVAL',
  HELD = 'HELD',
  READY_FOR_RELEASE = 'READY_FOR_RELEASE',
  SUBMITTED = 'SUBMITTED',
  PROCESSING = 'PROCESSING',
  RELEASED = 'RELEASED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  APPROVAL_REJECTED = 'APPROVAL_REJECTED',
}

export enum PAYMENT_CATEGORY {
  PENDING = 'PENDING',
  IN_PROCESSING = 'IN_PROCESSING',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
}

export enum CaseStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REOPENED = 'REOPENED',
}

export enum CreateCaseSteps {
  SELECT_PAYMENT = 'SELECT_PAYMENT',
  CASE_FORM = 'CASE_FORM',
  CASE_SUMMARY = 'CASE_SUMMARY',
}

export enum CreateCaseType {
  EXISTING_PAYMENT = 'EXISTING_PAYMENT',
  NON_EXISINTING_PAYMENT = 'NON_EXISTING_PAYMENT',
}

export enum TicketType {
  INVESTIGATION = 'INVESTIGATION',
  QUERY = 'QUERY',
}